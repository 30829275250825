import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialSharing from "../components/socialsharing"
import MailChimp from "../components/mailchimp"
import PopularPosts from "../components/popularposts"

import { rhythm, scale } from "../utils/typography"
import "../styles/site.css"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    var i = <div/>
    if (post.frontmatter.cover_image){
      i = <div>
            <Img 
              fluid={post.frontmatter.cover_image.childImageSharp.fluid}
              alt={post.frontmatter.cover_image_caption}  
            />
            <figcaption>{post.frontmatter.cover_image_caption}</figcaption>
          </div>
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO 
          title={post.frontmatter.title}
          description={post.excerpt}
          keywords={post.frontmatter.keywords}
        />
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-lg-offset-3">{i}</div>
            </div>
            <h1>{post.frontmatter.title}</h1>
            <h5><small>
              <em>Updated {post.frontmatter.date} by {post.frontmatter.author}</em>
            </small></h5>
            
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <div>
              <h6>
              Tags:
              {post.fields.tags.map(tag => (
                <Link
                  to={`/tags/${tag}`}
                >
                  {` `}{tag}
                </Link>
              ))}
              </h6>
            </div>
            <hr/>
                         
            
            <ul style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              listStyleType: `none`,
              padding: 0,}}
            >
            <li style={
              { listStyleType: `none`, 
                padding: 0,
              }
            }>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li style={
              { listStyleType: `none`, 
                padding: 0,
              }
            }>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
            </ul>  
          </div>
          <div className="col-lg-3 col-sm-12">
            <SocialSharing location={this.props.location} title={siteTitle} siteUrl={this.props.data.site.siteMetadata.siteUrl}/>
            <MailChimp />
            <PopularPosts />
          </div>
        </div>
        
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        cover_image {
          childImageSharp {
            resize (width:750, height: 750) {
              src
            }
            fluid (maxWidth: 786) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cover_image_caption
        keywords
        author
      }
      fields {
        tags
      }
    }
  }
`
